import { ITEM_TYPE, TOKEN_TYPE } from 'helper';

const initialState = {
    uid: 'Guest',
    nickname: '',
    localize: {},
    progress: [
        {
            type: 'food',
            current: 0,
            cost: 0,
            owned: 0,
        },
        {
            type: 'water',
            current: 0,
            cost: 0,
            owned: 0,
        },
        {
            type: 'hygiene',
            current: 0,
            cost: 0,
            owned: 0,
        },
    ],
    milestones: [],
    wheels: [],
    spinHistories: [],
    actionHistories: [],
    rewards: [],
    leaderboards: [],
    spinRemaining: 0,
    bunnyAnimation: false,
    spinAnimation: false,
    rewardOrder: false,
    choosenKit: false,
    hasShared: false,
    hasClaim: false,
};

const main = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'PUT_DATA':
            return { ...state, [payload.key]: payload.data };
        case 'INITIALIZE_INFO':
            const { nickname, token, claim_hygiene_left, claim_token_left, spin_remaining } = payload.data;
            const sorted = state.progress.map(a => a.type);
            return {
                ...state,
                nickname: nickname,
                hasShared: claim_hygiene_left > 0 ? false : true,
                hasClaim: claim_token_left > 0 ? false : true,
                spinRemaining: spin_remaining,
                progress: Object.keys(token).map((key) => ({
                    type: key,
                    current: token[key]?.progress_val,
                    cost: token[key]?.upgrade_cost,
                    owned: token[key]?.owned_tokens,
                })).sort((a, b) => sorted.indexOf(a) - sorted.indexOf(b)),
            };
        case 'GIVEN_KIT':
            return {
                ...state,
                choosenKit: payload.data,
            };
        case 'PLAY_BUNNY_GIF':
            return {
                ...state,
                bunnyAnimation: payload.data,
            };
        case 'STOP_BUNNY_GIF':
            return { ...state, bunnyAnimation: false };
        case 'PLAY_ANIMATION':
            return {
                ...state,
                spinAnimation: true,
                rewardOrder: payload.data.wheel_order,
                rewards: [{ ...payload.data, type: ITEM_TYPE }],
            };
        case 'STOP_ANIMATION':
            return {
                ...state,
                spinAnimation: false,
            };
        case 'OPEN_HISTORY_SPIN':
            return {
                ...state,
                spinHistories: payload.data,
            };
        case 'CLAIM_TOKEN_SHARING':
        case 'CLAIM_DAILY_TOKEN':
            return {
                ...state,
                rewards: [{ ...payload.data, type: TOKEN_TYPE, token_type: payload.data.token_type?.toLowerCase() === 'drink' ? 'water' : payload.data.token_type }],
            };
        case 'CLAIM_MILESTONE':
            return {
                ...state,
                rewards: payload.data.map((d) => ({ ...d, type: ITEM_TYPE })),
            };
        default:
            return state;
    }
};

export default main;
