import React from "react"
import Confirm from "./Confirm";
import History from "./History";
import Info from "./Info";
import Lucky from "./Lucky";
import Policy from "./Policy";
import Reward from "./Reward";
import Share from "./Share";

const ModalCont = () => {
    return (
        <>
            <Lucky />
            <Info />
            <Share />
            <Policy />
            <History />
            <Reward />
            <Confirm />
        </>
    )
}

export default ModalCont;