import { useEffect, useState } from "react";

function usePersistState(defaultValue, key) {
    const [value, setValue] = useState(() => {
        const data = window.localStorage.getItem(key);
        return data !== null ? JSON.parse(data) : defaultValue;
    });
    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
}

export default usePersistState;
