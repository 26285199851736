import classNames from "classnames";
import { useLocalize } from "helper";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { postMilestone } from "redux/actions/main";
import Tooltip from "./Tooltip";

const Levelup = () => {
	const milestones = useSelector((state) => state.main.milestones);
	return (
		<div className="level-up__container">
			<div className="milestone">
				<div className="bar">
					{milestones.map((milestone) => (
						<Level
							key={milestone?.level}
							level={milestone?.level}
							data={milestone}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

const Level = ({ level = 1, data = {} }) => {
	const dispatch = useDispatch();
	const levelClass = classNames([
		"level",
		`level-${level}`,
		data?.can_claim && "fill",
		data?.is_claimed && "claimed",
	]);
	const onLevelClick = () => {
		if (data?.can_claim && !data?.is_claimed) {
			dispatch(postMilestone(data?.milestone_log_id));
		}
	};
	const frameClass = classNames(["item__frame", data?.rarity?.toLowerCase()]);
	const [localize] = useLocalize();
	return (
		<div className={levelClass}>
			<span className="refrigerator">
				{localize("txt_level")} {level}
			</span>
			<div onClick={onLevelClick} className={"item"}>
				<div className={frameClass}>
					<Tooltip content={data?.item_name}>
						<div>
							<div className="item__overlay"> </div>
							<div className="item__img">
								<img alt="" src={data?.item_url} />
							</div>
						</div>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};

export default Levelup;
