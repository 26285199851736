import classNames from "classnames";
import React, { useState } from "react";
import BaseModal from "helper/component/basemodal";
import { useLocalize } from "helper";
import { Table } from "component";
import { useSelector } from "react-redux";

const History = () => {
	const [tab] = useState("history");
	const [localize] = useLocalize();
	const tabClass = (active) =>
		classNames(["btn tab gunar", active === tab && "active"]);
	const histories = useSelector((state) => state.main.spinHistories);
	return (
		<BaseModal tag={"history"} title={localize("txt_popup")}>
			<div className="tab__header">
				<button className={tabClass("history")}>
					<span>{localize("txt_history")}</span>
				</button>
			</div>
			<div key={tab} className={`tab__content animated fadeIn`}>
				<div className="history__container">
					<div className="table__wrapper">
						<Table heads={["txt_date", "txt_lucky_spin_prize"]}>
							{histories.map((history, index) => {
								return (
									<tr key={index}>
										<td>{history.created_at}</td>
										<td>{history.item_name}</td>
									</tr>
								);
							})}
						</Table>
					</div>
				</div>
			</div>
		</BaseModal>
	);
};

export default History;
