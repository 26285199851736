import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { usePromiseTracker } from "react-promise-tracker";

const LoadingScreen = (props) => {

	const { promiseInProgress } = usePromiseTracker({
		area: 'initialize'
	});
	return (
		<div
			className={`loading ${promiseInProgress ? 'open' : 'none'}`}
			style={{
				backgroundColor: `${props.backgroundColor}`
			}}
		>
			<div className="wp_content">
				{/* <p className="wp_tips">{this.props.text}</p> */}
				<img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/scavangerjourney/loader.gif" alt="" className="img" />
			</div>
		</div>
	);
}

LoadingScreen.defaultProps = {
	text: "Loading...",
	backgroundColor: "rgba(0, 0, 0, 1)",
};

LoadingScreen.propTypes = {
	text: PropTypes.string,
	type: PropTypes.string,
	backgroundColor: function (props, propName, componentName) {
		if (props[propName].indexOf("rgb") < 0) {
			if (props[propName].indexOf("rgba") < 0) {
				if (props[propName].indexOf("#") < 0) {
					return new Error(
						"Invalid prop `" +
						propName +
						"` supplied to" +
						" `" +
						componentName +
						"`. Only accept RGB, RGBA or Hex code."
					);
				}
			}
		}
	}
};

export default LoadingScreen;
