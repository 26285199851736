import React from "react";
import { useLocalize } from "helper";

const Table = ({ heads = [], children }) => {
	const [localize] = useLocalize();
	return (
		<table className="refrigerator">
			<thead>
				<tr>
					{heads.map((head, index) => (
						<th key={index}>{localize(head)}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{(children?.length > 0) ? (
					children
				) : (
					<tr>
						<td colSpan={heads.length}>{localize("txt_empty_list")}</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};

export { Table };
