import classNames from 'classnames';
import { useLocalize } from 'helper';
import React, { useCallback, useMemo }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postDailyToken } from 'redux/actions/main';
import { toggleToast } from 'redux/actions/modal';
import Tooltip from './Tooltip';

const Carekit = () => {
	const dispatch = useDispatch();
	const [localize] = useLocalize();
	const progress = useSelector(state => state.main.progress);
	const hasClaim = useSelector(state => state.main.hasClaim);
	const onFeedClick = () => dispatch(postDailyToken())
	return (
		<div className='care-kit__container'>
			<div className='foods'> {
				progress.map(kit => {
					return ( <Kit key={kit.type} {...kit} /> )
				})
			}
			</div>
			<div className='description'>
				<p className='refrigerator'>{localize('txt_carekit_desc')}</p>
			</div>
			<button onClick={onFeedClick} disabled={hasClaim} className='btn btn-feed refrigerator'>
				{!hasClaim && <div className='daily'>{localize('txt_free_daily')}</div>}
				{localize('txt_food_tokens')}
			</button>
		</div>
	);
};

const Kit = ({ type = 'hygiene', cost = 0, current = 0, owned = 0 }) => {
	const dispatch = useDispatch();
	const icoClass = classNames(['ico-kit', type]);
	const [localize] = useLocalize();
	const percentage = useMemo(() => {
		return (current / cost) * 100;
	}, [cost, current]);
	const tokenName = localize(`txt_${type.toLowerCase()}`);
	const onClick = useCallback((e) => {
		e.stopPropagation();

		if (percentage >= 100) {
			dispatch(
				toggleToast({
					state: true,
					type: 'message',
				})
			);
			return;
		}
		if (owned === 0) {
			dispatch(
				toggleToast({
					state: true,
					msg: 'NOT_ENOUGH_TOKEN_TO_UPGRADE'
				})
			);
			return;
		}
		dispatch({
            type : 'GIVEN_KIT',
            payload : {
                data : type,
            }
        });
	}, [percentage, type, owned, dispatch]);
	return (
		<div className='food__container'>
			<Tooltip content={tokenName} placement={'left'}>
				<div className={icoClass}></div>
			</Tooltip>
			<div onClick={onClick}  className='milestone'>
				<div className='points refrigerator'>
					{current}/{cost}
				</div>
				<div className='bar'>
					<div style={{ width: percentage + '%' }} className='bar__inner'></div>
				</div>
			</div>
		</div>
	);
};

export default Carekit;
