import { Howl } from "howler";

export const CDN =
    "https://cdngarenanow-a.akamaihd.net/gstaticid/BMG/flower/";
const HowlSounds = {};
const sounds = {
    bgm: {
        loop: true,
        mpeg: CDN + "sounds/FF_BGM_LatamDecorateXmas.mp3",
        wav: CDN + "sounds/FF_BGM_LatamDecorateXmas.wav",
    },
};
const audio = () => {
    function initialize() {
        for (let key in sounds) {
            HowlSounds[key] = new Howl({
                src: [sounds[key].mpeg, sounds[key].wav],
                loop: sounds[key].loop || false,
                preload: true,
            });
        }
    }
    function play(key) {
        HowlSounds[key].play();
    }
    function update(url, key = 'bgm') {
        HowlSounds[key].unload();
        HowlSounds[key] = new Howl({
            src: [url, url],
            loop: true,
            preload: true,
        });
        HowlSounds[key].play();
    }
    return { initialize, play, update };

};

export { audio };
