import Tooltip from "component/Tooltip";
import { getRegionByHostname, useLocalize } from "helper";
import BaseModal from "helper/component/basemodal";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// import useClipboard from "react-use-clipboard";
import copy from 'copy-to-clipboard';
import { postShare } from "redux/actions/main";
import { toggleModal } from "redux/actions/modal";

const Share = () => {
	const [localize] = useLocalize();

	const dispatch = useDispatch();
	const region = getRegionByHostname();
	const SHARE_URL =
		"https://share.garena.com/?collection=codm_golden_pet&region=" + region;
	// const [, setCopied] = useClipboard(SHARE_URL);
	const onShareClick = (type) => () => {
		if (type === 'copy') {
			copy(SHARE_URL)
		}
		// dispatch(postShare(type, setCopied, SHARE_URL));
		dispatch(postShare(type, () => copy(SHARE_URL), SHARE_URL));
	};
	const closeModal = () =>
		dispatch(
			toggleModal({
				name: 'share',
			})
		);
	useEffect(() => {
		const data = window.localStorage.getItem('sharing');
		if (data) {
			window.localStorage.removeItem("sharing");
			dispatch({
				type: "CLAIM_TOKEN_SHARING",
				payload: {
					data: JSON.parse(data)
				},
			});
		}
	}, [dispatch]);
	return (
		<BaseModal tag="share" title={localize("txt_system")}>
			<div className="text__head refrigerator">
				{localize("txt_modal_share_title")}
			</div>
			<div className="text__desc">{localize("txt_modal_share_content")}</div>
			<div className="group">
				{
					!(['TW', 'TH'].includes(region)) ? (<button onClick={onShareClick("wa")} className="btn btn-wa" />) : null
				}
				<Tooltip timer={1000} content={localize("txt_copied")} placement="bottom">
					<button onClick={onShareClick("copy")} className="btn btn-copy" />
				</Tooltip>
			</div>
			<button onClick={closeModal} className="btn btn-grey refrigerator">
				{localize("txt_back")}
			</button>
		</BaseModal>
	);
};

export default Share;
