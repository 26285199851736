// Game Related
window.promises = window.promises || {};

window.nativePromiseThen = (promiseId, data, error) => {
	if (error) {
		window.promises[promiseId].reject(data);
	} else {
		window.promises[promiseId].resolve(data);
	}
	delete window.promises[promiseId];
};

const generateUUID = () => {
	var d = new Date().getTime();
	var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		// eslint-disable-next-line
		return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
};

export const BrowserInfo = () => {
	const u = navigator.userAgent;
	return {
		//Mobile terminal browser version information
		trident: u.indexOf("Trident") > -1, //IE
		presto: u.indexOf("Presto") > -1, //opera
		webKit: u.indexOf("AppleWebKit") > -1, //apple, google
		// eslint-disable-next-line
		gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //Firefox
		mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //Whether it is a mobile terminal
		ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios terminal
		android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android terminal or uc browser
		iPhone: u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1, //Whether it is an iPhone or QQHD browser
		iPad: u.indexOf("iPad") > -1, //iPad
		// eslint-disable-next-line
		webApp: u.indexOf("Safari") == -1 //Whether the web application should have no header and bottom
	};
};

export const inGame = () => {
	if (window?.FreeFire || window?.webkit?.messageHandlers?.FreeFire) {
		return true;
	}
	return false;
};

export const gamePostMessage = (msg) => {
	const browser = {
		versions: BrowserInfo(),
		language: window.navigator.language.toLowerCase()
	};
	if (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {
		window?.webkit?.messageHandlers?.FreeFire.postMessage(msg);
	} else if (browser.versions.android) {
		window.FreeFire.postMessage(msg);
	}
};

export const gamePromise = (key, value = "") => {
	const promise = new Promise(function (resolve, reject) {
		const promiseId = generateUUID();
		window.promises[promiseId] = { resolve, reject };
		let msg = {
			PromiseId: promiseId
		};
		msg[key] = value;
		gamePostMessage(JSON.stringify(msg));
	});

	return promise;
};

export const deepLink = (url, fn) => {
	if (inGame()) {
		gamePostMessage(
			JSON.stringify({
				DeepLinkUrl: url
			})
		);
	} else {
		window.location.href = url;
	}
	let timer = setTimeout(() => {
		fn();
		clearTimeout(timer);
	}, 3000);
	document.addEventListener(
		"visibilitychange",
		() => {
			document.hidden && clearTimeout(timer);
		},
		{ once: true }
	);
};

export const game = {
	inGame,
	run: gamePromise,
	deepLink,
	checkScreenShotOpen: () => {
		if (!inGame()) {
			console.log("must run in game");
			return;
		}
		return gamePromise("CanCaptureScreenshot");
	},
	runScreenShot: () => {
		if (!inGame()) {
			console.log("must run in game");
			return;
		}
		return gamePromise("CaptureAndShareScreenshot");
	}
};
