export const toggleModal =
    ({ name, state = false, closeIcon = true, parentId = false }) =>
        (dispatch) => {
            dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                    name,
                    state,
                    closeIcon,
                },
            });
        };

export const toggleToast =
    ({ state = false, msg = '', type = 'error' }) =>
        (dispatch) => {
            dispatch({
                type: 'TOGGLE_TOAST',
                payload: {
                    state,
                    msg,
                    type,
                },
            });
        };

export const setMsg = (msg) => ({
    type: 'SET_MSG',
    payload: {
        msg,
    },
});

export const showCloseIcon = (closeIcon = true) => ({
    type: 'SHOW_CLOSE',
    payload: closeIcon,
});
