import React from 'react';
import BaseModal from 'helper/component/basemodal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocalize } from 'helper';
import { Table, Wheel } from 'component';
import { postSpin, postSpinHistory } from 'redux/actions/main';

const Lucky = () => {
	const dispatch = useDispatch();
	const [localize] = useLocalize();
	const animation = useSelector((state) => state.main.spinAnimation);
	const wheels = useSelector((state) => state.main.wheels, shallowEqual);
	const leaderboards = useSelector((state) => state.main.leaderboards, shallowEqual);
	const remaining = useSelector((state) => state.main.spinRemaining);
	const onHistoryClick = () => dispatch(postSpinHistory());
	const onEndAnimation = () => {
		setTimeout(() => {
			dispatch({
				type: 'STOP_ANIMATION',
			});
		}, 1200);
		document.querySelector('.close-lucky').disabled = false;
		document.querySelector('.close-lucky').style.pointerEvents = 'auto';
	};
	const onPlayAnimation = () => {
		dispatch(postSpin());
		document.querySelector('.close-lucky').disabled = true;
		document.querySelector('.close-lucky').style.pointerEvents = 'none';
	};
	return (
		<BaseModal tag={'lucky'} container={document.getElementById('Main')}>
			<div className='kv' />
			<div className='wheel__container'>
				<Wheel child={12} onEnd={onEndAnimation} data={wheels} />
				<div className='group'>
					<button onClick={onPlayAnimation} className='btn btn-spin refrigerator' disabled={animation || remaining === 0}>
						{`${localize('txt_spin_now')} ${remaining === 0 ? '' : `(${remaining})`}`}
					</button>
					<button onClick={onHistoryClick} className='btn btn-history' disabled={animation}></button>
				</div>
			</div>
			<div className='leaderboard__container'>
				<div className='leaderboard'>
					<div className='leaderboard__title refrigerator'>{localize('txt_lucky_soldiers')}</div>
					<div className='leaderboard__table'>
						<div className='table__wrapper'>
							<Table heads={['txt_soldiers', 'txt_lucky_spin_prize']}>
								{leaderboards.map((leaderboard, index) => {
									return (
										<tr key={index}>
											<td>{decodeURIComponent(leaderboard?.nickname)}</td>
											<td>{leaderboard?.item_name}</td>
										</tr>
									);
								})}
							</Table>
						</div>
					</div>
				</div>
			</div>
		</BaseModal>
	);
};

export default Lucky;
