import React, { useCallback, useEffect, useState } from "react";
import { audio, game, useLocalize, usePersistState } from "helper";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "redux/actions/modal";
import { Howler } from "howler";
import classNames from "classnames";
import Tooltip from "./Tooltip";

const Header = () => {
	const dispatch = useDispatch();
	const onClickInfo = useCallback(() => {
		dispatch(
			toggleModal({
				name: "info",
				state: true,
			})
		);
	}, [dispatch]);
	const [mute, setMute] = usePersistState(false, "audio_mute");
	const [inGameVolume, setInGameVolume] = useState(1);
	const tokens = useSelector((state) => state.main.progress);
	const [localize] = useLocalize();
	
	useEffect(() => {
		if (localize('bgm') !== 'bgm') {
			audio().update(localize('bgm'));
		}
	}, [localize])

	useEffect(() => {
		const visiblityChange = () => {
			if (document.visibilityState === "visible") {
				Howler.mute(mute);
			} else {
				Howler.mute(true);
			}
		};
		document.addEventListener("visibilitychange", visiblityChange);
		return () =>
			document.removeEventListener("visibilitychange", visiblityChange);
	}, [mute]);

	useEffect(() => {
		if (game.inGame()) {
			game.run("Volume", "music").then((res) => {
				if (res === "0") {
					setMute(true);
				} else {
					Howler.volume(res);
					// setMute(false);
				}
				setInGameVolume(res);
				// if (!mute) {
				audio().play("bgm");
				// }
			});
		} else {
			audio().play("bgm");
		}
	}, [setMute]);

	useEffect(() => {
		Howler.mute(mute);
	}, [mute]);

	const onClickBGM = useCallback(
		(e) => {
			e.stopPropagation();
			if (inGameVolume === "0") {
				Howler.volume(1);
			} else {
				Howler.volume(inGameVolume);
			}
			setMute(!mute);
			Howler.mute(!mute);
		},
		[mute, inGameVolume, setMute]
	);

	const bgmClass = classNames(["btn btn-bgm", mute ? "bgm-on" : "bgm-off"]);

	return (
		<div className="header">
			<div className="container">
				<div className="group">
					<button onClick={onClickInfo} className="btn btn-info"></button>
					<button onClick={onClickBGM} className={bgmClass}></button>
				</div>
				<div className="title"></div>
				<div className="balance">
					{tokens.map((token) => {
						return <Token key={token.type} count={token.owned} type={token.type} />;
					})}
				</div>
			</div>
		</div>
	);
};

const Token = ({ type = "hygiene", count = 0 }) => {
	const icoClass = classNames(["ico-token", `${type}__token`]);
	const [localize] = useLocalize();
	const tokenName = localize(`txt_${type.toLowerCase()}`);
	return (
		<div className="tokens">
			<Tooltip placement="bottom" content={tokenName}>
				<div>
					<div className={icoClass}></div>
					<span className="refrigerator">{count}</span>
				</div>
			</Tooltip>
		</div>
	);
};

export default Header;
