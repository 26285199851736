import classNames from "classnames";
import { ITEM_TYPE, TOKEN_TYPE, useLocalize } from "helper";
import BaseModal from "helper/component/basemodal";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toggleModal } from "redux/actions/modal";

const Reward = () => {
	const [localize] = useLocalize();
	const dispatch = useDispatch();
	const rewards = useSelector((state) => state.main.rewards, shallowEqual);
	const closeModal = () =>
		dispatch(
			toggleModal({
				name: "reward",
			})
		);
	const frameClass = classNames([
		"item__frame",
		rewards?.[0]?.type,
		rewards?.[0]?.item_rarity?.toLowerCase(),
	]);
	const imgClass = classNames(["item__img", rewards?.[0]?.token_type]);

	return (
		<BaseModal tag="reward" title={localize("txt_system")}>
			<div className="text__head refrigerator">
				{localize("txt_modal_congrats_title")}
			</div>
			<div className="text__desc">
				<p>
					{rewards?.[0]?.type === ITEM_TYPE
						? localize("txt_modal_congrats_content").replace(
								"{name}",
								rewards?.[0]?.item_name
						  )
						: localize("txt_modal_congrats_token_content")
								.replace("{num}", `${rewards?.[0]?.amount}`)
								.replace(
									"{token_name}",
									localize(`txt_${rewards?.[0]?.token_type?.toLowerCase()}`)
								)}
				</p>
			</div>
			{rewards.map((reward, index) => {
				const tokenName = localize(
					`txt_${reward?.token_type?.toLowerCase()}`
				).concat(" ", ` x${reward?.amount}`);
				return (
					<div key={index} className="item">
						<div className={frameClass}>
							<div className={imgClass}>
								{reward.type === ITEM_TYPE ? (
									<img alt="" src={reward?.img_url || reward?.image_url} />
								) : null}
							</div>
						</div>
						<div className="item__name gunar">
							{reward?.type === TOKEN_TYPE ? tokenName : reward?.item_name}
						</div>
					</div>
				);
			})}
			<button onClick={closeModal} className="btn btn-yellow refrigerator">
				{localize("txt_confirm")}
			</button>
		</BaseModal>
	);
};

export default Reward;
