import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import "./style.scss";

const Loader = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        <div className={`overlay ${promiseInProgress ? 'active' : ``}`}>
            {promiseInProgress ? <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/Support/puff.svg" alt="" className="img" /> : ""}
        </div>
    );
}

export default Loader;
