import classNames from "classnames";
import React, { useCallback} from "react";
import { useSelector } from "react-redux";

const Wheel = ({ child = 0, onEnd = () => {}, data = []}) => {
	const animation = useSelector((state) => state.main.spinAnimation);
	const rewardOrder = useSelector((state) => state.main.rewardOrder);
	const coordinate = useCallback(
		(order) => {
			const angle = 360 / child;
			const angleItem = angle * order - angle / 2;
			const radians = angleItem * (Math.PI / 180);
			const x = Math.cos(radians) * 1.6;
			const y = Math.sin(radians) * 1.6;
			return `translate(${x}rem, ${y}rem) rotate(${90 + angleItem}deg)`;
		},
		[child]
	);

	const wheelClass = classNames([
		"wheel__rotation",
        `reward-${rewardOrder || '0'}`,
		animation ? `animated` : 'last-state' ,
	]);

	return (
		<div className="wheel">
			<div className="arrow"></div>
			<div onAnimationEnd={onEnd} className={wheelClass}>
				<div className="wheel__content">
					{data.map((item, idx) => (
						<ItemWheel key={item.wheel_order} data={item} order={item.wheel_order} coor={coordinate(item.wheel_order)} />
					))}
				</div>
			</div>
		</div>
	);
};

const ItemWheel = ({ order, coor, data = [] }) => {
    const frameClass = classNames(['item__frame', data?.item_rarity?.toLowerCase()]);
	const orderClass = classNames(["item", `order-${order}`]);
	return (
		<div
			style={{
				transform: coor,
				// transform: `translate(${Math.cos(15 * (Math.PI / 180)) * (4.51 )}rem, ${Math.sin(15 * (Math.PI / 180)) * (4.51)}rem )`
				// transform: `translate(${Math.cos(15 * (Math.PI / 180)) * 1.6}rem, ${Math.sin(15 * (Math.PI / 180)) * 1.6}rem ) rotate(${90 + 15}deg)`
			}}
			className={orderClass}
		>
			<div className={frameClass}>
				<div className="item__img">
					<img alt='' src={data.img_url || " "} />
				</div>
			</div>
		</div>
	);
};
export default Wheel;
