import classNames from "classnames";
import { useLocalize } from "helper";
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const useBunny = () => {
	const [localize] = useLocalize();
	return (anim) => {
		if (anim === 'water' || anim === 'food') {
			return localize(`img_animal_love`);
		}
		if (anim === 'hygiene') {
			return localize(`img_animal_plus`);
		}
		return "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
	}
}

const Bunny = () => {
    const dispatch = useDispatch();
    const timeoutRef = useRef();
	const movement = useBunny();
	const anim = useSelector((state) => state.main.bunnyAnimation);
    useEffect(() => {
		// console.log(imgRef?.current?.offsetHeight)
		if (anim) {
			timeoutRef.current = setTimeout(() => {
				dispatch({
					type: "STOP_BUNNY_GIF",
				});
			}, 6200);
		}
		return () => {
			clearTimeout(timeoutRef.current);
		};
    } , [anim, dispatch]);
	const bunnyClass = classNames(["bunny", anim]);
	return (
		<div className={bunnyClass} >
			{<img  src={movement(anim)} alt="" />}
		</div>
	);
};

export default Bunny;
