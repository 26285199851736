import React, { useRef } from "react"

import Tippy from "@tippyjs/react"

function Tooltip({
  content = "",
  children,
  timer = 2000,
  placement = "right",
  ...props
}) {
  const timerRef = useRef()
  const onShow = (instance) => {
    if (!timer) return
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      instance.hide()
    }, timer)
  }
  return (
    <Tippy
      className={`tooltip`}
      content={<p className="refrigrator-font tippy-text">{content}</p>}
      trigger="click"
      onShow={onShow}
      onHide={() => clearTimeout(timerRef.current)}
      arrow={true}
      placement={placement}
      ignoreAttributes={true}
      {...props}
    >
      {children}
    </Tippy>
  )
}

export default Tooltip
