import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { toggleToast } from "redux/actions/modal";

import Modal from "react-responsive-modal";
import { useLocalize } from "helper";

const notCloseable = [
    "TOAST_EVENT_END",
    "TOAST_EVENTOVER",
    "TOAST_EVENT_NOTOPEN",
    "TOAST_NETWORK_ERROR",
    "VISITOR_NOT_ALLOWED",
    "TOAST_SERVER_BUSY",
    "TOAST_WRONG_REGION",
    "TOAST_SERVER_TIMEOUT",
    "TOAST_LOGIN_FAILED",
    "TOAST_TOKEN_EXPIRED",
    "NOT_LOGGED_IN",
    "?err=41",
    'NO_CHARACTER'
];

const blackScreenMsg = [
    "NOT_LOGGED_IN",
    "TOAST_LOGIN_FAILED"
];

const Toast = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state.modal.toast);
    const msg = useSelector((state) => state.modal.msg);
    const type = useSelector((state) => state.modal.type);

    const [localize] = useLocalize();
    const uid = useSelector((state) => state.main.uid);

    const closeToast = () => dispatch(toggleToast({ state: false }));
    const autoClose = !notCloseable.includes(msg.toUpperCase());
    const loginFailed = !uid && blackScreenMsg.includes(msg);
    const toastClass = classNames([`animated toast`, loginFailed ? "non-bg" : ""]);
    return (
        <Modal
            open={isOpen}
            animationDuration={!uid && blackScreenMsg.includes(msg) ? 0 : 300}
            styles={{
                overlay: {
                    background: loginFailed ? "rgba(0,0,0,1)" : "",
                },
            }}
            showCloseIcon={autoClose}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            focusTrapped={false}
            onClose={closeToast}
            classNames={{
                modal: toastClass,
                overlay: "toast__overlay",
                closeButton: 'btn-close',
                modalAnimationIn: `scaleY`,
                modalAnimationOut: `scaleYreverse`,
            }}
            closeIcon={<div className="ico-close" />}
            center
        >
            <div className='toast__container modal__container'>
                <div className={`toast__title modal__title refrigerator`}>
                    <h3>{localize(type === 'message' ? 'txt_system' : 'txt_warning')}</h3>
                    <div className={`modal__logo`} />
                </div> {
                    type ==='message'? (<CompletedMission />) : (  <div className='toast__content error modal__content gunar'>{
                        localize(msg) === msg ? localize(msg.toLowerCase()) : localize(msg)
                    }</div>)
                }
            </div>

        </Modal>
    );
}

const CompletedMission = () => {
    const dispatch = useDispatch();
    const close = () => dispatch(toggleToast({state: false}));
    const [localize] = useLocalize();
    return (
        <div className='toast__content modal__content'>
            <div className='text__head refrigerator'>{localize('txt_modal_warning_carekit_title')}</div>
            <div className='text__desc gunar'>
                <p>{localize('txt_modal_warning_carekit_desc')}</p>
            </div>
            <button onClick={close} className="btn btn-yellow refrigerator">{localize('txt_confirm')}</button>
        </div>
    )
}

export default Toast;
