import React, { lazy, Suspense } from "react"
import { Routes, BrowserRouter, Route } from "react-router-dom"
import { ErrorBoundary } from "@sentry/react"
// Component

import ViewportRestrict from "helper/component/viewport"
import Loader from "helper/component/loader"
import NotFound from "helper/component/404"
import LoadingScreen from "helper/component/loadingscreen"
import Fallback from "helper/component/fallback"
import { componentLoader, useLocalize } from "helper"
import { Header, Toast } from "component"
import ModalCont from "component/Modal"

// Pages
const Home = lazy(() => componentLoader(() => import("pages/Home")))

export const Main = () => {
    const [localize] = useLocalize();
    return (
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
            <div id="Main" className="main-panel" >
                <LoadingScreen />
                <ErrorBoundary fallback={Fallback}>
                    <Suspense fallback={<div></div>}>
                        <ViewportRestrict display={true} text={localize('txt_viewport_restrict')} type="portrait" />
                        <Loader />
                        <Header />
                        <ModalCont />
                        <Toast />
                        <div className="content-container">
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </div>
                    </Suspense>
                </ErrorBoundary>
            </div>
        </BrowserRouter>
    )
}

export default Main
